<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link>
      <p class="divider">|</p>
      <router-link to="/tdd-evaluation-tool">TDD Evaluator</router-link>
    </div>
    <hr />
    <router-view />
  </div>
</template>
<script>
export default {
  title: "Home | David Frenzel",
};
</script>

<style lang="scss">
@import "./style/_typography.scss";

html,
body {
  box-sizing: border-box;
  margin: 0 !important;
  padding: 0 !important;
  background: $dark-gray;
}

#app {
  min-height: 100%;
  width: 100%;
  overflow-x: none;
  overflow-y: auto;
  background: $dark-gray;
}

#nav {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 5vh;
  padding: 0px 20px;
}
</style>
